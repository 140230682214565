<template>
  <div class="wrapper">
    <div class="foot">
      <div class="con">
        <div class="copyright">
          <p>Copyright©2011-2019 北京中科希望物联网络科技有限公司 版权所有</p>
          <p>
            <a
              target="_blank"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502038816"
              style="
                display: inline-block;
                text-decoration: none;
                height: 15px;
                line-height: 15px;
              "
              ><img
                width="14px"
                height="14px"
                border="0"
                src="//www.z-hope.cn/resource/ba.png"
                style="float: left"
                loading="lazy"
              /><font color="#999" style="font-size: 12px"
                >京公网安备11010502038816号</font
              ></a
            >&nbsp;<a target="_blank" href="https://beian.miit.gov.cn/"
              ><font color="#999" style="font-size: 12px"
                >京ICP备14053908号-1</font
              ></a
            >
          </p>
        </div>
        <div class="phone">
          <!-- <p>客服电话：010-64926887</p>
          <p>传真电话：010-64926887</p> -->
          <p>地址：北京市海淀区中关村大街22号1003室</p>
          <p>服务时间：9：00~18：00 | 工作日</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'footerIndex'
};
</script>

<style lang="less" scoped>
.wrapper {
  .foot {
    width: 100%;
    height: 130px;
    background-color: #2c2c2c;
    padding: 30px 0 0 0;
    .con {
      width: 1120px;
      margin: 0 auto;
      padding: 0 40px;
      .copyright {
        width: 600px;
        float: left;
        color: #99a1a8;
        p {
          font-size: 12px;
          line-height: 20px;
          color: #8a8e91;
          a {
            margin: 0 15px;
            font-size: 16px;
            line-height: 30px;
          }
        }
      }
      .phone {
        width: 300px;
        float: right;
        color: #8a8e91;
        p {
          font-size: 14px;
          line-height: 24px;
          color: #99a1a8;
          text-align: right;
        }
      }
    }
  }
}
</style>