import axios from 'axios'

async function compare() {
  let last = window.localStorage.getItem('app_version') // 旧版本
  let current = await queryVersion() // 新版本
  if(!last) {
    setVersion(current)
  }
  if(last && last != current) { // 新旧版本不一样
    setVersion(current)
    window.location.reload() // 自动刷新浏览器
  }
}

function setVersion(version) {
  window.localStorage.setItem('app_version', version)
}

/**
 * 请求新版本
 * @returns 
 */
async function queryVersion() {
  return axios.get(window.location.origin + window.location.pathname + './app-version.json?r=' + Math.random())
    .then(({data}) => {
      return data.appVersion
    })
}

compare()