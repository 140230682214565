import Vue from 'vue'
import Router from 'vue-router'
import home from './home';
import terraceIntro from './terrace-intro'
import monitorDevice from './monitor-device'
import verifyPlan from './verify-plan'
import monitorPlan from './monitor-plan'
import verifyDevice from './verify-device'
import sell from './sell'
import news from './news'
import about from './about'

Vue.use(Router)

// Uncaught (in promise) NavigationDuplicated: Avoided redundant navigation to current location: "xxx".
// 以下代码解决路由地址重复的报错问题
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  ...home,
  ...terraceIntro, 
  ...monitorDevice,
  ...verifyDevice,
  ...verifyPlan,
  ...monitorPlan,
  ...sell,
  ...news,
  ...about,
]

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

export default router
