<template>
  <MpItem :itemData="item13" :isShowHonor="true"/>
</template>

<script>
import {item13} from "@/utils/config/mp-item"

import MpItem from "@/components/mp-item.vue"

export default {
  name:'MpItem13',
  components:{
    MpItem,
  },
  data(){
    return {
      item13,
    }
  }
}
</script>

<style>

</style>