export const sellList = [
    {   id:1,
        icon: "京",
        compare:"北方区域（北京分公司）",
        tel:"",
        telHref:"tel:010-64926887",
        phone:"15001174965",
        phoneHref:"tel:15001174965",
        email:"yangxinyu@z-hope.com.cn",
        name:"杨新宇"
    },
    {
        id:6,
        icon: "冀",
        compare:"北方区域（河北分公司）",
        phone:"15001174965",
        phoneHref:"tel:15001174965",
        email:"yangxinyu@z-hope.com.cn",
        name:"杨新宇",
    },
    {
        id:7,
        icon: "鲁",
        compare:"北方区域（山东分公司）",
        phone:"17777879552",
        phoneHref:"tel:17777879552",
        email:"sunjing@z-hope.com.cn",
        name:"孙竞",
    },
    {   id:2,
        icon: "粤",
        compare:"华南区域（广州分公司）",
        tel:"020-38662409",
        telHref:"tel:020-38662409",
        phone:"13609733894",
        phoneHref:"tel:13609733894",
        email:"wangrong@z-hope.com.cn",
        name:"王蓉"
    },
    {   id:3,
        icon: "沪",
        compare:"华东区域（上海分公司）",
        phone:"13003160791",
        phoneHref:"tel:13003160791",
        email:"male@z-hope.com.cn",
        name:"马乐"
    },
    {   id:31,
        icon: "苏",
        compare:"华东区域（苏州分公司）",
        phone:"13925192511",
        phoneHref:"tel:13925192511",
        email:"xupingtao@z-hope.com.cn",
        name:"许平涛"
    },
    {
        id:4,
        icon: "川",
        compare:"西南区域（成都分公司）",
        phone:"13880884707",
        phoneHref:"tel:13880884707",
        email:"tengyan@z-hope.com.cn",
        name:"滕艳",
    },
    {
        id:5,
        icon: "陕",
        compare:"华中区域（西安分公司） ",
        phone:"13925192511",
        phoneHref:"tel：13925192511",
        email:"xupingtao@z-hope.com.cn",
        name:"许平涛",
    },


]