import { render, staticRenderFns } from "./item9.vue?vue&type=template&id=8dda36f4"
import script from "./item9.vue?vue&type=script&lang=js"
export * from "./item9.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_css-loader@6.11.0_webpack@5.98.0__lodash@4.17.21__s6l3nt2j5z2qyyvij7jcrz2r5m/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports