<template>
  <MpItem :itemData="item9" :isShowHonor="true"/>
</template>

<script>
import {item9} from "@/utils/config/mp-item"

import MpItem from "@/components/mp-item.vue"

export default {
  name:'MpItem9',
  components:{
    MpItem,
  },
  data(){
    return {
      item9,
    }
  }
}
</script>

<style>

</style>