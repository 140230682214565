<template>
  <MpItem :itemData="item15" :isShowHonor="true"/>
</template>

<script>
import {item15} from "@/utils/config/mp-item"

import MpItem from "@/components/mp-item.vue"

export default {
  name:'MpItem15',
  components:{
    MpItem,
  },
  data(){
    return {
      item15,
    }
  }
}
</script>

<style>

</style>